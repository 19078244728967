import React from 'react';
import Page from '../components/Page';
import SEO from '../components/seo';
import Home from '../components/Home';

const DiscoverPage = () => (
  <Page>
    <meta name="theme-color" content="#000000" />
    <SEO
      title="Liberty Call Distilling Co."
      keywords={[
        'San Diego',
        'Distilleries',
        'Craft',
        'Spirits',
        'Cocktails',
        'Distillery',
        'Whiskey',
        'Gin',
        'Rum',
        'Vodka',
        'Liquor',
        'Local',
        'California',
      ]}
    />
    <Home />
  </Page>
);

export default DiscoverPage;
